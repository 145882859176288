import React, { PropsWithChildren } from "react";

import { InlineWidget } from "react-calendly";
import styled from "styled-components";

interface Props {
  className?: string;
}

const Calendly = ({ className }: PropsWithChildren<Props>) => (
  <Container className={className} id="calendly">
    <InlineWidget
      url="https://calendly.com/studiodsg/consulenza"
      styles={{ height: "auto" }}
    />
  </Container>
);

export default Calendly;

const Container = styled.div`
  iframe {
    height: 700px !important;
    @media (max-width: 1080px) {
      height: 2620px !important;
    }
    @media (max-width: 730px) {
      height: 2450px !important;
    }
    @media (max-width: 500px) {
      height: 2500px !important;
    }
    @media (max-width: 320px) {
      height: 2650px !important;
    }
  }
`;
